import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const jobList = createAsyncThunk("jobList", async (params, thunkApi) => {
	const { url = "", ...others } = params;
	try {
		const response = await SERVER({
			url,
			params: others,
			method: "GET",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const addJobData = createAsyncThunk("addJobData", async (params, thunkApi) => {
	const { url = "", data = {}, method = "POST" } = params;
	try {
		const response = await SERVER(url, {
			data,
			method,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const deleteJobData = createAsyncThunk("deleteJobData", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const viewJobData = createAsyncThunk("viewJobData", async (parameters, thunkApi) => {
	const { url = "", params } = parameters;
	try {
		const response = await SERVER({
			url,
			params,
			method: "GET",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const jobCalenderList = createAsyncThunk("jobCalenderList", async (params, thunkApi) => {
	const { url = "", ...others } = params;
	try {
		const response = await SERVER({
			url,
			params: others,
			method: "GET",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const jobVerifyData = createAsyncThunk("jobVerifyData", async (params, thunkApi) => {
	const { url = "", ...others } = params;
	try {
		const response = await SERVER({
			url,
			data: others,
			method: "PATCH",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const updateStatusData = createAsyncThunk("updateStatusData", async (params, thunkApi) => {
	const { url = "", ...others } = params;
	try {
		const response = await SERVER({
			url,
			data: others,
			method: "PATCH",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const endTaskApi = createAsyncThunk("endTaskApi", async (params, thunkApi) => {
	const { url = "", data } = params;
	try {
		const response = await SERVER({
			url,
			data,
			method: "POST",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

// export const downloadTaskApi = createAsyncThunk("downloadTaskApi", async (params, thunkApi) => {
// 	const { url = "", data } = params;
// 	try {
// 		const response = await SERVER({
// 			url,
// 			data,
// 			method: "POST",
// 		});
// 		const blob = new Blob([response], { type: "application/pdf" });
// 		const urls = window.URL.createObjectURL(blob);
// 		const a = document.createElement("a");
// 		console.log(a,'a')
// 		a.href = urls;
// 		a.download = `Task-${data?.job_id}`;
// 		a.click();
		
		
// 		// return response.data;
// 	} catch (error) {
		
// 		return thunkApi.rejectWithValue(error);
// 	}
// });

export const downloadTaskApi = createAsyncThunk("downloadTaskApi", async (params, thunkApi) => {
    const { url = "", data } = params;
    try {
        const response = await SERVER({
            url,
            data,
            method: "POST",
            responseType: "blob"
        });

        const blob = await new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Task-${data?.job_id}`;
        link.click();
        return blob;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})
export const downloadSingleTaskApi = createAsyncThunk("downloadSingleTaskApi", async (params, thunkApi) => {
    const { url = "", data } = params;
    try {
        const response = await SERVER({
            url,
            data,
            method: "POST",
            responseType: "blob"
        });

        const blob = await new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Task-${data?.job_id}`;
        link.click();
        return blob;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})
export const deleteTaskviewJobData = createAsyncThunk("deleteTaskviewJobData", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const deleteTaskJobData = createAsyncThunk("deleteTaskJobData", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const seeMoreTaskData = createAsyncThunk("seeMoreTaskData", async (params, thunkApi) => {
	const { url = "", ...others } = params;
	try {
		const response = await SERVER({
			url,
			params: others,
			method: "GET",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});


export const undoJobUpdate = createAsyncThunk("undoJob", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER({
			url,
			method: "POST",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});


export const undoJobUpdateTask = createAsyncThunk("undoJobUpdateTask", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER({
			url,
			method: "POST",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});