import { createSlice } from "@reduxjs/toolkit";
import { notificationList, addNotification, updateNotificationApi, updateNotificationAllApi,notificationHeaderList } from "redux/api/services/notificationService";

const initialState = {
	notificationList: {
		loading: false,
		data: [],
		error: null,
	},
	addNotification: {
		loading: false,
		data: null,
		error: null,
	},
	updateNotification: {
		loading: false,
		data: null,
		error: null,
	},
	updateNotificationAll: {
		loading: false,
		data: null,
		error: null,
	},
	notificationHeaderList: {
		loading: false,
		data: [],
		error: null,
	},
};

export const directorySlice = createSlice({
	name: "notification",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			//notification list
			.addCase(notificationList.pending, (state, action) => {
				state.notificationList.loading = true;
			})
			.addCase(notificationList.fulfilled, (state, action) => {
				state.notificationList.loading = false;
				state.notificationList.data = action.payload;
				state.notificationList.error = null;
			})
			.addCase(notificationList.rejected, (state, action) => {
				state.notificationList.loading = false;
				state.notificationList.error = action.payload;
			})
			//add notification
			.addCase(addNotification.pending, (state, action) => {
				state.addNotification.loading = true;
			})
			.addCase(addNotification.fulfilled, (state, action) => {
				state.addNotification.loading = false;
				state.addNotification.data = action.payload;
				state.addNotification.error = null;
			})
			.addCase(addNotification.rejected, (state, action) => {
				state.addNotification.loading = false;
				state.addNotification.error = action.payload;
			})
			//clear single notification
			.addCase(updateNotificationApi.pending, (state, action) => {
				state.updateNotification.loading = true;
			})
			.addCase(updateNotificationApi.fulfilled, (state, action) => {
				state.updateNotification.loading = false;
				state.updateNotification.data = action.payload;
				state.updateNotification.error = null;
			})
			.addCase(updateNotificationApi.rejected, (state, action) => {
				state.updateNotification.loading = false;
				state.updateNotification.error = action.payload;
			})
			//all notification clear
			.addCase(updateNotificationAllApi.pending, (state, action) => {
				state.updateNotificationAll.loading = true;
			})
			.addCase(updateNotificationAllApi.fulfilled, (state, action) => {
				state.updateNotificationAll.loading = false;
				state.updateNotificationAll.data = action.payload;
				state.updateNotificationAll.error = null;
			})
			.addCase(updateNotificationAllApi.rejected, (state, action) => {
				state.updateNotificationAll.loading = false;
				state.updateNotificationAll.error = action.payload;
			})
			// Header Notification
			.addCase(notificationHeaderList.pending, (state, action) => {
				state.notificationHeaderList.loading = true;
			})
			.addCase(notificationHeaderList.fulfilled, (state, action) => {
				state.notificationHeaderList.loading = false;
				state.notificationHeaderList.data = action.payload;
				state.notificationHeaderList.error = null;
			})
			.addCase(notificationHeaderList.rejected, (state, action) => {
				state.notificationHeaderList.loading = false;
				state.notificationHeaderList.error = action.payload;
			})
			;
			
	},
});

export default directorySlice.reducer;
