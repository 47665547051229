export const authEndPoints = {
	user: {
		userLogin: "admin/auth/login",
		userRegister: "admin/auth/register",
		userForgetPassword: "admin/auth/forget-password",
		userResetPassword: "admin/auth/reset-password",
		userChangePassword: "admin/auth/change-password",
		logout: (role = "admin") => role + "/auth/logout",
	},
	admin: {
		adminLogin: "admin/auth/login",
	},
	directory: {
		list: "admin/directory",
		newDirectory: "admin/directory",
		removeDirectory: (id) => `admin/directory/${id}`,
		editDirectory: (id) => `admin/directory/${id}`,
		viewDirectory: (id) => `admin/directory/${id}`,
	},
	role: {
		rolesList: "admin/masters/role",
		newRole: "admin/masters/role",
		removeRole: (id) => `admin/masters/role/${id}`,
		editRole: (id) => `admin/masters/role/${id}`,
		permissionRole: "admin/permission",
		savePermission: (id) => `admin/permission/${id}`,
	},
	essential: {
		essentialSelect: "admin/masters/listing",
	},
	approved: {
		approvedData: (id) => `admin/user/${id}`,
	},
	profile: {
		getme: "admin/auth/me",
		updateme: "admin/auth/update",
		changepassword: "admin/auth/change-password",
		tokenGenerate: "admin/auth/token/update",
	},
	employee: {
		newEmployee: "admin/user",
		employeeList: "admin/user",
		removeEmployee: (id) => `admin/user/${id}`,
		editEmployee: (id) => `admin/user/${id}`,
		viewEmployee: (id) => `admin/user/${id}`,
		employeeInvoice: "admin/invoice",
		status: (id) => `admin/user/status/${id}`,
		employeeApproval: (id) => `admin/user/${id}`,
	},
	form: {
		newForm: "admin/form",
		formList: "admin/form",
		editForm: (id) => `admin/form/${id}`,
		viewForm: (id) => `admin/form/${id}`,
		deleteForm: (id) => `admin/form/${id}`,
		duplicate: 'admin/form/duplicate',
		formById: (id) => `admin/form/${id}`,
		formUndo: (id) => `admin/form/undo/${id}`,
	},
	job: {
		jobCalenderList: "admin/job/calander",
		jobList: "admin/job",
		addJob: "admin/job",

		endTask: (id) => `admin/job/end-task/${id}`,
		downLoadTask: `admin/job/form/download`,
		downloadSingleTask:`admin/job/form/download`,
		editJob: (id) => `admin/job/${id}`,
		viewJob: (id) => `admin/job/${id}`,
		deleteJob: (id) => `admin/job/${id}`,
		deleteAllJob: (id) => `admin/job/remove/${id}`,
		verifyJob: (id) => `admin/job/verify/${id}`,
		statusUpdate: (id) => `admin/job/status/${id}`,
		taskViewJobDelete: (id) => `admin/job/${id}`,
		taskViewSeeMore: (id) => `admin/job/upcomming/${id}`,
		jobUndo: (id) => `admin/job/undo/${id}`,
		jobUndoTask: (id) => `admin/job/undo-all/${id}`,
	},
	invoice: {
		invoiceList: "admin/invoice",
		invoiceSeparateList: (id) => `admin/invoice/user/${id}`,
		invoiceView: (id) => `admin/invoice/${id}`,
		pdfInvoice: (id) => `admin/invoice/${id}/pdf`,
		approveInvoice: (id) => `admin/invoice/status/${id}`,
		miscAddInvoice: "admin/invoice/expense",
		miscDeleteInvoice: (id) => `admin/invoice/expense/${id}`,
		miscEditInvoice: (id) => `admin/invoice/expense/${id}`,
	},

	notification: {
		list: "admin/notification",
		newNotification: "admin/notification",
		clearSingleNotification: (id) => `admin/notification/${id}`,
		clearAllNotification: `admin/notification/allStatus`,
		headerList: "admin/notification",
	},

	dashboard: {
		dashboardTable: `admin/dashboard`,
	},
	company: {
		list: "admin/company",
		newCompany: "admin/company",
		editCompany: (id) => `admin/company/${id}`,
		viewCompany: (id) => `admin/company/${id}`,
		removeCompany: (id) => `admin/company/${id}`,
	},
	activelog: {
		list: "admin/activelog",
	},
};
