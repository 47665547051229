import { createSlice } from "@reduxjs/toolkit"
import { companyListData, addCompanyData, viewCompanyData,deleteCompanyData} from "redux/api/services/companyService"
const initialState = {
    companyList: {
        loading: false,
        data: [],
        error: null,
    },
    addCompany: {
        loading: false,
        data: null,
        error: null,
    },
    deleteCompany: {
        loading: false,
        data: null,
        error: null,
    },
    viewCompany: {
        loading: false,
        data: null,
        error: null,
    }
}
export const companySlice = createSlice({
    name:"company",
    initialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder
        //list
        .addCase(companyListData .pending, (state, action) => {
            state.companyList.loading = true;
        })
        .addCase(companyListData .fulfilled, (state, action) => {
            state.companyList.loading = false;
            state.companyList.data = action.payload;
            state.companyList.error = null;
        })
        .addCase(companyListData .rejected, (state, action) => {
            state.companyList.loading = false;
            state.companyList.error = action.payload;
        })
         //Add company
         .addCase(addCompanyData.pending, (state, action) => {
            state.addCompany.loading = true;
        })
        .addCase(addCompanyData.fulfilled, (state, action) => {
            state.addCompany.loading = false;
            state.addCompany.data = action.payload;
            state.addCompany.error = null;
        })
        .addCase(addCompanyData.rejected, (state, action) => {
            state.addCompany.loading = false;
            state.addCompany.error = action.payload;
        })
        //Delete company
        .addCase(deleteCompanyData.pending, (state, action) => {
            state.deleteCompany.loading = true;
        })
        .addCase(deleteCompanyData.fulfilled, (state, action) => {
            state.deleteCompany.loading = false;
            state.deleteCompany.data = action.payload;
            state.deleteCompany.error = null;
        })
        .addCase(deleteCompanyData.rejected, (state, action) => {
            state.deleteCompany.loading = false;
            state.deleteCompany.error = action.payload;
        })
         //view company
         .addCase(viewCompanyData.pending, (state, action) => {
            state.viewCompany.loading = true;
        })
        .addCase(viewCompanyData.fulfilled, (state, action) => {
            state.viewCompany.loading = false;
            state.viewCompany.data = action.payload;
            state.viewCompany.error = null;
        })
        .addCase(viewCompanyData.rejected, (state, action) => {
            state.viewCompany.loading = false;
            state.viewCompany.error = action.payload;
        })
    },
})
export default companySlice.reducer