/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import AdminHeader from "./utils/AdminHeader";
import AdminSidebar from "./utils/AdminSidebar";

const AdminLayout = () => {
	const navigate = useNavigate();
	const [access, setAccess] = useState(true);

	const token = localStorage.getItem("token");
	const role = localStorage.getItem("role");
	useEffect(() => {
		role === "1" ? setAccess(true) : setAccess(false);
	}, []);
	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (access === false) {
			navigate("/404");
		}
	}, [access, navigate]);
	return (
		<div className="cg-wrapper">
			{access && (
				<>
					<AdminSidebar />
					<section className="cg-content">
						<AdminHeader />
						<Box>
							<Outlet />
						</Box>
					</section>
				</>
			)}
		</div>
	);
};

export default AdminLayout;
