import React from 'react'

const UnAuthed = () => {
  return (
    <div>
        404 Not Found
    </div>
  )
}

export default UnAuthed
