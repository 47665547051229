import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";
export const companyListData = createAsyncThunk("companyList", async (params, thunkApi) => {
    const { url = "", ...others } = params;
    try {
        const response = await SERVER({
            url,
            params: others,
            method: "GET",
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})
export const addCompanyData = createAsyncThunk("addCompanyData", async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
        const response = await SERVER.post(url, {
            ...data,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const viewCompanyData = createAsyncThunk("companyView", async (params, thunkApi) => {
    const { url = "", ...others } = params;
    try {
        const response = await SERVER({
            url,
            params: others,
            method: "GET",
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})
export const deleteCompanyData = createAsyncThunk("deleteCompanyData", async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
        const response = await SERVER.delete(url, {
            ...data,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
