/* eslint-disable jsx-a11y/alt-text */
import { IconButton } from '@mui/material'
import React from 'react'

function IconComponent({ src }) {
    return (
        <IconButton sx={{
            background: '#D5E8CA'
        }}>
            <img src={src} />
        </IconButton>
    )
}

export default IconComponent