import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const notificationList = createAsyncThunk("notificationList", async (parameter, thunkApi) => {
	const { url = "", params, ...others } = parameter;
	try {
		const response = await SERVER({
			url,
			params,
			method: "GET",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const notificationHeaderList = createAsyncThunk("notificationHeaderList", async (parameter, thunkApi) => {
	const { url = "", params, ...others } = parameter;
	try {
		const response = await SERVER({
			url,
			params,
			method: "GET",
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const addNotification = createAsyncThunk("addNotification", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const updateNotificationApi = createAsyncThunk("updateNotificationApi", async (params, thunkApi) => {
	const { url = "", data } = params;
	try {
		const response = await SERVER(url, {
			method: "PATCH",
			data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const updateNotificationAllApi = createAsyncThunk("updateNotificationAllApi", async (params, thunkApi) => {
	const { url = "", data } = params;
	try {
		const response = await SERVER(url, {
			method: "POST",
			data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
