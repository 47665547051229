import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

// export const EMPLOYEEGET = createAsyncThunk('EMPLOYEEGET', async (allParams, thunkApi) => {
//     const { params, endpoints } = allParams
//     try {
//         const response = await SERVER.get(endpoints['url'],{
//             params,
//         })
//        return response.data
//     } catch (error) {
//         return thunkApi.rejectWithValue(error?.response?.data ?? error)
//     }
// })

// export const EMPLOYEEPOST = createAsyncThunk('EMPLOYEEPOST', async (allParams, thunkApi) => {
//     const { data, endpoints } = allParams
//     try {
//         const response = await SERVER.post(endpoints['url'], data ? data : {})
//         return response?.data
//     } catch (error) {
//         return thunkApi.rejectWithValue(error?.response?.data ?? error)
//     }
// })

// export const EMPLOYEEPUT = createAsyncThunk('EMPLOYEEPUT', async (allParams, thunkApi) => {
//     const { data, endpoints } = allParams
//     try {
//         const response = await SERVER.post(endpoints['url'], data ? data : {})
//         return response?.data
//     } catch (error) {
//         return thunkApi.rejectWithValue(error?.response?.data ?? error)
//     }
// })

// export const EMPLOYEEDELETE = createAsyncThunk('EMPLOYEEDELETE', async (allParams, thunkApi) => {
//     const { data, endpoints } = allParams
//     try {
//         const response = await SERVER.post(endpoints['url'], data ? data : {})
//         return response?.data
//     } catch (error) {
//         return thunkApi.rejectWithValue(error?.response?.data ?? error)
//     }
// })

export const addEmployeeData = createAsyncThunk("addEmployeeyData", async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
        const response = await SERVER.post(url, {
            ...data,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }

        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
// get
export const employeeListData = createAsyncThunk(
    "employeeListData",
    async (params, thunkApi) => {
        const { url = "", ...others } = params;
        try {
            const response = await SERVER({
                url,
                params: others,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteEmployeeData = createAsyncThunk("deleteDirectoryData", async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
        const response = await SERVER.delete(url, {
            ...data,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const viewEmployeeData = createAsyncThunk("viewEmployeeData", async (params, thunkApi) => {
    const { url = "", ...others } = params;
    try {
        const response = await SERVER({
            url,
            params: others,
            method: "GET",
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})

// employeeInvoice
export const EmployeesInvoice = createAsyncThunk("EmployeeInvoice", async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
        const response = await SERVER.postForm( url, {
                ...data,
            }, 
        );
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const employeeStatusData = createAsyncThunk("employeeStatusData", async (params, thunkApi) => {
    const { url = "", data, ...others } = params;
    try {
        const response = await SERVER({
            url,
            params: others,
            data: data,
            method: "PATCH",
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})