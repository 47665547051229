/* eslint-disable react-hooks/exhaustive-deps */
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Doorbell, Chat, Friends, GoogleForms, Project, TaskIcon, masterIcon, profileIcon } from "helpers/images";
import { useEffect, useState } from "react";
import companyLogo from "assets/images/company-logo.svg";
import collapseRight from "assets/images/collapse-right.svg";
import smallLogo from "assets/images/small-logo.png";
import IconComponent from "components/icon";
import DescriptionIcon from '@mui/icons-material/Description';
import { IconButton } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';

const AdminSidebar = () => {

    const [collapse, setCollapse] = useState(false)
    const roleName = localStorage.getItem("roleName");
    const [width, setWidth] = useState("");

    function getSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {

        window.addEventListener("resize", getSize);
        if (width === "") {
            setCollapse(false);
        } else if (width <= 768) {
            setCollapse(true);
        } else {
            setCollapse(false);
        }
        return () => {
            window.removeEventListener("resize", getSize);
        };
    }, [window.innerWidth]);

    return (
        <Sidebar
            className="app"
            collapsed={collapse}
            responsive={true}
            width="208px"
            backgroundColor="#F0FAF5"
            transitionDuration={500}>
            <Menu>
                <div className="cg-logo">
                    {collapse ? (
                        <div>
                            <Link to={`/${roleName}/dashboard`}>
                                <img className="cg-logo-mobile-img" src={smallLogo} alt="logo" width={"80px"} />
                            </Link>

                            <div className="cg-logo-lg-leftarrow">
                                <img
                                    className="rotate"
                                    src={collapseRight}
                                    alt="arrow"
                                    width={"15px"}
                                    height={"15px"}
                                    onClick={() => setCollapse(false)}
                                />
                            </div>
                        </div>
                    ) :
                        <div>
                            <Link to={`/${roleName}/dashboard`}>
                                <img className="cg-logo-lg-img" src={companyLogo} alt="logo" />
                            </Link>
                            <div className="cg-logo-lg-leftarrow">
                                <img
                                    src={collapseRight}
                                    alt="arrow"
                                    width={"15px"}
                                    height={"15px"}
                                    onClick={() => setCollapse(true)}
                                />
                            </div>

                        </div>
                    }
                </div>

                <MenuItem
                    component={<Link to="admin/jobs" className="link" />}
                    icon={<IconComponent src={Project} />}
                >
                    Jobs
                </MenuItem>

                <MenuItem
                    component={<Link to="admin/directory" className="link" />}
                    icon={<IconComponent src={Friends}
                    />}
                >
                    Directory
                </MenuItem>


                <MenuItem
                    component={<Link to="https://web.whatsapp.com/" className="link" target="blank" />}
                    icon={<IconComponent src={Chat} />}
                >
                    Chats
                </MenuItem>

                <SubMenu label="Forms" icon={<IconComponent src={GoogleForms} />}>
                    <MenuItem
                        component={<Link to="admin/forms" className="link" />}
                        icon={<IconComponent src={GoogleForms} />}
                    >
                        Default Forms
                    </MenuItem>
                    <MenuItem
                        component={<Link to="admin/customized-form" className="link" />}
                        icon={<IconComponent src={GoogleForms} />}
                    >
                        Customized Forms
                    </MenuItem>
                </SubMenu>
                <MenuItem
                    component={<Link to="admin/notifications" className="link" />}
                    icon={<IconComponent src={Doorbell} />}
                >
                    Notification
                </MenuItem>
                <MenuItem
                    component={<Link to="admin/tasks" className="link" />}
                    icon={<IconComponent src={TaskIcon} />}
                >
                    Tasks
                </MenuItem>
                <MenuItem
                    component={<Link to="admin/invoice" className="link" />}
                    icon={
                        <IconButton sx={{
                            background: '#D5E8CA'
                        }}>
                            <DescriptionIcon sx={{ color: 'black', fontSize: '16px' }} />
                        </IconButton>
                    }
                >
                    Invoices
                </MenuItem>

                <MenuItem
                    icon={<IconComponent src={profileIcon} />}
                    component={<Link to='admin/changePassword' className="link" />}
                >
                    Change Password
                </MenuItem>

                <MenuItem icon={<IconComponent src={masterIcon} />}
                    component={<Link to="admin/roles" className="link" />}
                >Roles</MenuItem>

                <MenuItem icon={<IconButton sx={{
                    background: '#D5E8CA'
                }}>
                    <HistoryIcon sx={{ color: 'black', fontSize: '16px' }} />
                </IconButton>}
                    component={<Link to="admin/activelog" className="link" />}
                >
                    Log Activity
                </MenuItem>

            </Menu>
        </Sidebar>
    );
};

export default AdminSidebar;
