import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomToast = ({ closeToast, message, onUndo }) => {
  const [timer, setTimer] = useState(15);
  const [showUndo, setShowUndo] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setShowUndo(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
      }}
    >
      <div style={{ flex: 1 }}>{message}</div>

      {showUndo && (
        <div
          onClick={onUndo}
          style={{
            // marginLeft: 10,
            cursor: "pointer",
            borderBottom: "3px solid red",
            display: "inline-block",
            color: "red",
          }}
        >
          Undo
        </div>
      )}
      {showUndo && (
        <div
          style={{
            // marginLeft: 10,
            width: "35px",
            textAlign: "right",
          }}
        >
          <span>{timer}s</span>
        </div>
      )}
    </div>
  );
};

export default CustomToast;
