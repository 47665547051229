import { createSlice } from "@reduxjs/toolkit";
import {

    employeeListData,
    deleteEmployeeData,
    addEmployeeData,
    viewEmployeeData,
    EmployeesInvoice,
    employeeStatusData
} from "redux/api/services/employeeService";

const initialState = {
    // employeeList: {
    //     loading: false,
    //     data: null,
    //     error: null,
    // },
    // singleEmployee: {
    //     loading: false,
    //     data: null,
    //     error: null,
    // },
    // editEmployee: {
    //     loading: false,
    //     data: null,
    //     error: null,
    // },
    deleteEmployee: {
        loading: false,
        data: null,
        error: null,
    },
    addEmployee: {
        loading: false,
        data: null,
        error: null,
    },
    listEmployee: {
        loading: false,
        data: null,
        error: null,
    },
    viewEmployee: {
        loading: false,
        data: null,
        error: null,
    },
    employeeInvoice:{
        loading: false,
        data: null,
        error: null,
    },
    employeeStatus:{
        loading: false,
        data: null,
        error: null,
    }

};

export const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //GET
        builder
            //Auth login
            // .addCase(addEmployeeData.pending, (state, action) => {
            //     state.addEmployee.loading = true;
            // })
            // .addCase(addEmployeeData.fulfilled, (state, action) => {
            //     state.addEmployee.loading = false;
            //     state.addEmployee.data = action.payload;
            //     state.addEmployee.error = null;
            // })
            // .addCase(addEmployeeData.rejected, (state, action) => {
            //     state.addEmployee.loading = false;
            //     state.addEmployee.error = action.payload;
            // })
            //   employeeList
            .addCase(employeeListData.pending, (state, action) => {
                state.listEmployee.loading = true;
            })
            .addCase(employeeListData.fulfilled, (state, action) => {
                state.listEmployee.loading = false;
                state.listEmployee.data = action.payload;
                state.listEmployee.error = false;
            })
            .addCase(employeeListData.rejected, (state, action) => {
                state.listEmployee.loading = false;
                state.listEmployee.error = action.payload;
            })
            //Delete Employee
            .addCase(deleteEmployeeData.pending, (state, action) => {
                state.deleteEmployee.loading = true;
            })
            .addCase(deleteEmployeeData.fulfilled, (state, action) => {
                state.deleteEmployee.loading = false;
                state.deleteEmployee.data = action.payload;
                state.deleteEmployee.error = null;
            })
            .addCase(deleteEmployeeData.rejected, (state, action) => {
                state.deleteEmployee.loading = false;
                state.deleteEmployee.error = action.payload;
            })
            //add Employee
            .addCase(addEmployeeData.pending, (state, action) => {
                state.addEmployee.loading = true;
            })
            .addCase(addEmployeeData.fulfilled, (state, action) => {
                state.addEmployee.loading = false;
                state.addEmployee.data = action.payload;
                state.addEmployee.error = null;
            })
            .addCase(addEmployeeData.rejected, (state, action) => {
                state.addEmployee.loading = false;
                state.addEmployee.error = action.payload;
            })
            //view Employee
            .addCase(viewEmployeeData.pending, (state, action) => {
                state.viewEmployee.loading = true;
            })
            .addCase(viewEmployeeData.fulfilled, (state, action) => {
                state.viewEmployee.loading = false;
                state.viewEmployee.data = action.payload;
                state.viewEmployee.error = null;
            })
            .addCase(viewEmployeeData.rejected, (state, action) => {
                state.viewEmployee.loading = false;
                state.viewEmployee.error = action.payload;
            })
            // employee invoice
            .addCase(EmployeesInvoice.pending,(state,action)=>{
                state.employeeInvoice.loading = true;
            })
            .addCase(EmployeesInvoice.fulfilled,(state,action)=>{
                state.employeeInvoice.loading=false;
                state.employeeInvoice.data=action.payload;
                state.employeeInvoice.error=null;
            })
            .addCase(EmployeesInvoice.rejected,(state,action)=>{
                state.employeeInvoice.loading=false;
                state.employeeInvoice.error =action.payload;
            })
            // EmployeeStatus
            .addCase(employeeStatusData.pending,(state,action)=>{
                state.employeeStatus.loading = true;
            })
            .addCase(employeeStatusData.fulfilled,(state,action)=>{
                state.employeeStatus.loading=false;
                state.employeeStatus.data=action.payload;
                state.employeeStatus.error=null;
            })
            .addCase(employeeStatusData.rejected,(state,action)=>{
                state.employeeStatus.loading=false;
                state.employeeStatus.error =action.payload;
            })
    },
});

export default employeeSlice.reducer;
