/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate, Link } from "react-router-dom";
import { errorAlert, successAlert } from "helpers/globalFunctions";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { notificationHeaderList, notificationList, updateNotificationAllApi, updateNotificationApi } from "redux/api/services/notificationService";
import { authEndPoints } from "helpers/endpoints";
import { getMe } from "redux/api/services/profileService";
import { logout } from "redux/api/services/authService";

const AdminHeader = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const role = localStorage.getItem("roleName");
	const [getdata, setGetdata] = useState("");
	const [apiData, setData] = useState([]);
	const logoutFn = async () => {
		try {
			const response = await dispatch(logout({ url: authEndPoints.user.logout(role === "admin" ? "admin" : "user") })).unwrap();
			localStorage.removeItem("role");
			localStorage.removeItem("token");
			localStorage.removeItem("roleName");
			navigate("/");
			successAlert("Logged out successfully");
		} catch (error) {
			errorAlert(error?.error);
		}
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const notificationData = useSelector((state) => state.notification.notificationList.data?.data);
	const employeeData = useSelector((state) => state.profile.getme.data?.data);
	const notificationHeaderData = useSelector((state) => state.notification.notificationHeaderList.data?.data?.data);

	// getme data
	const gettingData = async () => {
		const parameter = {
			url: authEndPoints.profile.getme,
		};
		try {
			const response = await dispatch(getMe(parameter)).unwrap();
			setGetdata(response);
		} catch (errors) {
			errorAlert(errors?.error);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const viewProfile = () => {
		navigate(`/${role}/myProfile`);
	};
	const fecthHeaderNotification = async () => {
		try {
			const parameters = {
				url: `${authEndPoints.notification.headerList}`,
				params: {
					is_read: 0,
				},
			};
			const response = await dispatch(notificationHeaderList(parameters)).unwrap();
			setData(response.data.data);
		} catch (err) {
			console.log(err, "err");
		}
	};
	const clearNotification = useCallback(async (id) => {
		const parameters = {
			url: `${authEndPoints.notification.clearSingleNotification(id)}`,
			data: {
				is_read: 1,
			},
		};
		try {
			await dispatch(updateNotificationApi(parameters)).unwrap();
			fecthHeaderNotification();
		} catch (errors) {
			// errorAlert(errors?.error);
		}
	}, []);

	const clearAllNotification = useCallback(async (id) => {
		const parameters = {
			url: `${authEndPoints.notification.clearAllNotification}`,
			data: {
				is_read: 1,
			},
		};
		try {
			await dispatch(updateNotificationAllApi(parameters)).unwrap();
			fecthHeaderNotification();
		} catch (errors) {
			// errorAlert(errors?.error);
		}
	}, []);

	useEffect(() => {
		fecthHeaderNotification();
		gettingData();
	}, []);

	// useEffect(() => {
	// 	gettingData();
	// }, []);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static" className="cg-header">
				<Toolbar>
					<IconButton
						style={{ backgroundColor: "#fffff" }}
						size="large"
						edge="start"
						// color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
					></IconButton>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>

					<Stack direction={"row"} gap={3} alignItems={"center"}>
						<Box>
							{/* {role === "admin" && ( */}
							<Badge badgeContent={notificationHeaderData?.length ?? 0} color="secondary" onClick={handleClick} sx={{ cursor: "pointer" }}>
								<NotificationsIcon sx={{ color: "black" }} />
							</Badge>
							{/* )} */}
						</Box>
						<Avatar src={employeeData?.image} sx={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={() => viewProfile()} />

						<IconButton onClick={logoutFn}>
							<LogoutIcon sx={{ color: "black" }} />
						</IconButton>
					</Stack>

					<Menu
						anchorEl={anchorEl}
						id="account-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					>
						<List sx={{ width: "100%", maxWidth: 360 }}>
							{notificationHeaderData?.length === 0 ? (
								<ListItem alignItems="flex-start">
									<ListItemText secondary={<React.Fragment>No Notification</React.Fragment>} />
								</ListItem>
							) : (
								notificationHeaderData?.map((notification, index) => {
									return (
										<Fragment key={index}>
											{index <= 2 ? (
												<ListItem
													alignItems="flex-start"
													key={index}
													sx={{ cursor: notification?.type !== "announcement" && "pointer" }}
													onClick={() => {
														if (notification?.type !== "announcement") {
															if (notification.deleted === 1) {
																if (role === "admin") {
																	navigate("/admin/tasks/" + notification.type_id);
																	clearNotification(notification.id);
																} else {
																	navigate("/supervisor/tasks/" + notification.type_id);
																	clearNotification(notification.id);
																}
															} else {
																clearNotification(notification.id);
															}
														}
													}}
												>
													<ListItemAvatar>
														<Avatar alt="Remy Sharp" src="" />
													</ListItemAvatar>
													<ListItemText primary={notification.title} secondary={<React.Fragment>{notification.message}</React.Fragment>} />
												</ListItem>
											) : null
											}

											{
												(index === notificationHeaderData?.length - 1) ? (
													<ListItem
														components={Link}
														alignItems="flex-start"
														onClick={() => {
															clearAllNotification(notification.id);
															navigate(`/${role}/notifications`);
														}}
													>
														<ListItemText
															secondary={
																// <Link
																// 	to={`/${role}/notifications`}
																// 	style={{
																// 		textDecoration: "unset",
																// 		color: "gray",
																// 	}}
																// >
																<Box sx={{ textAlign: "center", cursor: "pointer" }}>{"View All Notification"}</Box>
																// </Link>
															}
														/>
													</ListItem>
												) : null
											}
										</Fragment>
									);
								})
							)}
						</List>
					</Menu>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default AdminHeader;
