import { createSlice } from "@reduxjs/toolkit";
import { activeLogListData } from "redux/api/services/activelogServices";
const initialState={
    activitylogList:{
        loading: false,
        data: [],
        error: null,
    },
}
export const activitySlice = createSlice({
    name:"activitylog",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        // list
        .addCase(activeLogListData.pending,(state,action)=>{
            state.activitylogList.loading = true;
        })
       .addCase(activeLogListData.fulfilled,(state,action)=>{
        state.activitylogList.loading=false;
        state.activitylogList.data = action.payload;
        state.activitylogList.error = null;
       })
       .addCase(activeLogListData.rejected,(state,action)=>{
state.activitylogList.loading = false;
state.activitylogList.error = action.payload;
       })
    },
})
export default activitySlice.reducer